import React, { Component } from 'react'
import { recordSession, updateSession} from '../../Api/Client'


export class Base extends Component {
  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  componentDidMount(){
    this.scrollToTop()

    recordSession(window.location.search)

    if(super.componentDidMount){
      super.componentDidMount()
    }

    const when = new Date().getTime()
    const key = 'did_' + when
    const description = 'Visited '+document.location.pathname

    updateSession(key, description)
  }
}

export default Base
