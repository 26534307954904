import React from "react";
import "./index.scss";
import App from "./App";
import { render } from "react-snapshot";
import * as serviceWorker from "./serviceWorker";
import "./ritual/fonts/Garnett-Regular.ttf";
import "./ritual/fonts/Garnett-Semibold.ttf";
import { BrowserRouter as Router } from "react-router-dom";

render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
