import React, { useState } from "react";
import ritual_logo from "../../assets/ritual_logo.svg";
import { setNewsLetterSubscribed } from "../../utils/localstorage";

const ZAPIER_HOOK_URL =
  "https://hooks.zapier.com/hooks/catch/11648943/3ys1kit/";

const Newsletter = ({ onSubmit }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    document.body.style.position = "static";

    fetch(ZAPIER_HOOK_URL, {
      mode: "no-cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email,
        anon_id: window.analytics?.user()?.anonymousId(),
      }),
    });

    onSubmit();
    setNewsLetterSubscribed();
  };

  return (
    <div className="subscribe_modal-backdrop">
      <div className="subscribe_modal">
        <img src={ritual_logo} alt="ritual_logo" />
        <p className="subscribe_form-title">Stay connected</p>
        <p className="subscribe_form-text">
          To continue reading this article and gain access to Dr. Susan
          Heitler’s latest writings and her team’s work, enter your email to
          subscribe.
        </p>
        <form onSubmit={handleSubmit} className="subscribe_form">
          <input
            type="email"
            required
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="subscribe_button" type="submit">
            Subscribe
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
