import React, { Component } from 'react'
import TopHeader from '../../Header/component/TopHeader.js'
import { newsletterSignup } from '../../Api/Client'

export class Content extends Component {
  state = {
    email: '',
    signedUp: false,
    errors: {}
  }

  fields = [
      'email',
  ]

  validators = {
    'email': value => {
      if(!value || value.length === 0){
        return 'Please enter an email.'
      }

      if(!value.match(/^[a-zA-Z0-9.\+\_]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/g)){
        return 'Please enter a valid email'
      }

      return null
    },
  }

  validate(name, value){
    const error = this.validators[name](value)

    this.setState({
      errors: {
        [name]: error
      }
    })

    return error
  }

  onChange = (e) => {
    const self = this
    const name = e.target.name
    const value = e.target.value

    this.setState({
      [name]: value
    }, () => {
      self.validate(
          name,
          value
      )
    })
  }

  onSignup = (e) => {
    e.preventDefault()

    this.setState({
        errors: {}
      },
      this.handleSignup
    )

    return false
  }

  handleSignup = () => {
    var self = this
    var errors_found = false

    this.fields.forEach((name) => {
      const error = self.validate(name, self.state[name])

      if(!errors_found && error){
        errors_found = error
      }
    })

    if(errors_found){
      return false;
    }

    newsletterSignup(
        this.state.email
    ).then(
      response => {
        this.setState({signedUp: true})
      }
    ).catch(
      error => {
        console.log(error.response)
      }
    )
  }

  signupForm = () => {
    return (
      <div className="newsletter-form form">
         <h1>Newsletter</h1>
         <p>
            Welcome to the Power of Two Quick Tips Newsletter!
            Enter your email address below to receive periodic marriage tips from Power of Two.
         </p>

         <form action='.' method='POST'>
           <div id="div_id_username" className="ctrlHolder ">
             <label htmlFor="id_username">
                 Email<span className="required-marker">*</span>
             </label>
             <div className="error">
              {this.state.errors.email}
             </div>
           </div>

           <input
              autoFocus
              type="text"
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              maxLength="80"
              className="textinput textInput"
          />

           <input type="submit"
              className='button newsletter-signup-button'
              onClick={this.onSignup}
              value='Submit »'
           />
         </form>
      </div>
    )
  }

  render(){
    if(this.state.signedUp){
      return (
        <div className='newsletter-signed-up'>
          <p>
            Your email has been submitted!
            Look out for a newsletter for Power of Two soon.
          </p>
          <p>
            And come back any time if you'd like to sign up for our full marriage saver program!
          </p>
        </div>
      )
    }

    return this.signupForm()
  }
}

export default Content
