import React from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Logo from "../Logo";
import { redirectToRitual } from "../../utils";

const REDIRECT_DELAY = 2000;
const MATCHING_URL = "https://ourritual.com/ritual-take-the-quiz";

const RedirectPage = ({ from, url = MATCHING_URL }) => {
  const timeoutRef = useRef();

  const location = useLocation();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      redirectToRitual({ url, utm_campaign: location.from, utm_medium: from });
    }, REDIRECT_DELAY);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="redirect-screen">
      <div className="redirect-screen__content">
        <Logo />
        <div className="text">
          You are redirected to OurRitual, Power of 2 partner
        </div>
      </div>
    </div>
  );
};

export default RedirectPage;
