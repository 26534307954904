import React from "react";
import { Link } from "react-router-dom";

const InfoRight = ({ data }) => {
  return (
    <div className="info-right">
      <div className="info-right-row-1">
        <Link to="/signup/" className="info-right-row-1-get-started">
          get started now
        </Link>
        <Link to="/landing/intro" className="info-right-row-1-take-tour">
          Take our relationship quiz →
        </Link>
      </div>
      <div className="info-right-row-2">
        {data.info_media.includes("youtube") ? (
          <div className="info-right-row-2-video">
            <iframe
              title="Youtube Video"
              width="340"
              height="221"
              src={data.info_media}
              frameborder="0"
              allowfullscreen=""
            />
          </div>
        ) : (
          <div className="info-right-row-2-image">
            <img src={data.info_media} alt="Info" />
          </div>
        )}

        <div className="info-right-row-2-links">
          <Link to="/signup/" className="info-right-row-2-links-learn">
            learn more
          </Link>
          <Link to="/landing/intro" className="info-right-row-2-links-sign-up">
            sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InfoRight;
