import React, { Component } from 'react'
import { Link } from "react-router-dom"

export class Content extends Component {
  render(){
    return (
      <>
      <div className="info-banner-section section">
        <div className='section-wrapper'>
          <p className="text-center hero-title">About us</p>
          <div className="about-head">
              <p className="text-center hero-subtext">
              Our mission is to help as many couples as possible live happier,
              healthier and more loving lives together. We do that by delivering the most engaging,
              effective, and convenient marriage education in the world.
              </p>
          </div>
          <div className="text-center seam-button">
            <Link to={{pathname: "/signup/", from: "about" }}>
              <img src='/static/img/arrow_button.png' className='about-signup' alt='Signup'/>
            </Link>
            <br/>
            <div className='about-join'>
              <strong>Join now!</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="info-description-section section">
        <div className='section-wrapper'>
          <div className="sub-section info-description-text">
              <h2 className="section-title">Power of Two is a unique online program that:</h2>
              <ul className="section-text">
                  <li>Teaches you how to stop fighting.</li>
                  <li>Improves communication, builds trust and enhances intimacy.</li>
                  <li>Includes videos, podcasts, worksheets, and interactive exercises.</li>
                  <li>Works on your own schedule, alone or with your partner.</li>
                  <li>Is for couples at all stages — dating, engaged or married for years.</li>
              </ul>
          </div>
          <div className="sub-section info-description-faq">
              <Link to="/faq/">
                <div className='faq-icon'>
                  <span>?</span>
                </div>
                <div className='faq-icon-text'>
                  <strong>Read our FAQs</strong>
                </div>
              </Link>
          </div>
        </div>
      </div>

      <div className="about-quiz-section section">
        <div className='section-wrapper'>
          <div className="about-quiz-section-left">
              <h2 className="section-title">Take the Power of Two Relationship Quiz.</h2>
              <Link to={{pathname:"/landing/intro", from: "about"}}>
                <img src="/static/img/quizGraphic.png" width="215" height="87" alt="quiz_graphic"/>
              </Link>
              <p className="section-text">See how your relationship measures up and find out what areas could be improved!</p>
              <div className='relationship-quiz'>
                <Link to={{pathname:"/landing/intro", from: "about"}} className="relationship-quiz-button button button-large">Begin the Relationship Quiz</Link>
              </div>
          </div>
          <div className="about-quiz-section-right">
              <p className="section-title">In Power of Two you will learn how to:</p>
              <ul className="section-text">
                  <li>Maintain an emotionally calm, supportive relationship.</li>
                  <li>Communicate without arguing or compromising.</li>
                  <li>Interact without being controlling or feeling controlled.</li>
                  <li>Listen so your partner feels heard.</li>
                  <li>Speak so that your partner will listen.</li>
                  <li>Make satisfying win-win decisions together.</li>
                  <li>Incubate positivity, intimacy and trust.</li>
              </ul>
          </div>
        </div>
      </div>

      <div className="about-program-desc-section section">
        <div className='section-wrapper'>
          <div className="row">
            <hr className='divider'/>
          </div>
          <div className="row">
              <div className="about-program-desc-text">
                <p className="text-center big-blue">
                    The program has been scientifically proven to enrich intimate relationships. It's an excellent substitute or
                    supplement to marriage counseling-online.
                </p>
              </div>
          </div>

          <div className="row about-program-learn-more">
              <hr className='divider divider-half divider-section'/>
              <div className="about-program-desc-citation-link">
                  <a href="https://www.researchgate.net/publication/221810241_A_Randomized_Clinical_Trial_of_Online-Biblio_Relationship_Education_for_Expectant_Couples" target="_blank">Learn More</a>
              </div>
              <hr style={{'float': 'none'}} className='divider divider-half divider-section'/>
          </div>
        </div>
      </div>


      <div className="about-program-team-section section">

            <div className="about-program-team-section-main text-center">
                <p className="section-title big-title">Created by</p>

                <img src="/static/img/team/susan.png" className="creator-icon"/>
                <p className="team-member-name">Dr. Susan Heitler</p>
                <p className="team-member-job">Founder</p>
                <hr className='divider'/>

                <div className="section-text">
                    <p>Dr. Susan Heitler has been saving marriages in danger of divorce as a clinical psychologist for over 30 years.
                        She’s a graduate of Harvard University, holds a master's degree in education from Boston University and a
                        doctorate in clinical psychology from New York University. In 1993, Susan Heitler Ph.D. wrote her first book for
                        clinical psychologists, From Conflict to Resolution. In 1997, realizing that lay-people needed their own book,
                        she wrote the world-renowned marriage help book (published in 6 languages), <a href="http://www.amazon.com/The-Power-Two-Secrets-Marriage/dp/1572240598?ie=UTF8&amp;keywords=power%20of%20two%20workbook&amp;qid=1459968400&amp;ref_=sr_1_2&amp;s=books&amp;sr=1-2" target="_blank">The Power of Two: Secrets to a
                        Strong &amp; Loving Marriage</a>, on which this site is based. Dr. Heitler’s newest book, <a href="http://www.amazon.com/Prescriptions-Without-Pills-Depression-Anxiety/dp/1630478105" target="_blank">Prescriptions Without Pills:
                        For Relief from Depression, Anger, Anxiety, and More</a> offers techniques for resolving the problems that have been
                        provoking uncomfortable emotions.
                    </p>
                    <br/>
                    <p>Dr. Heitler is quoted frequently in national media as an expert on relationship skills. She currently posts
                        regularly on sites such as <strong>Huffington Post</strong>, <strong>Psychology Today</strong> and <strong>YourTango</strong>. Dr, Heitler was featured on the CBS
                        Early Show where anchor Harry Smith described her as "the most influential person in my life — my
                        therapist."
                    </p>
                    <br/>
                    <p>For more information, see <a href="http://prescriptionswithoutpills.com" target="_blank">Dr. Heitler's website</a>.</p>
                </div>
            </div>

          <div className="section-container text-center">
            <div className="small-12 columns">
              <img src="/static/img/team/abigail.png" className="creator-icon"/>
              <p className="team-member-name">Dr. Abigail Hirsch</p>
              <p className="team-member-job">Founding CEO</p>
              <hr className='divider'/>

              <div className="section-text">
                  <p>Dr. Hirsch co-authored the original Power of Two Workbook and leads the online coaching team.</p>
                  <br/>
                  <p>She holds a B.A. in Quantitative Studies of Social Policy from Harvard University, an M.A. in Educational
                      Psychology from the University of Colorado, Denver and a Ph.D. in Clinical Psychology from the University of
                      Massachusetts, Boston.
                  </p>
                  <br/>
                  <p>Abigail has been happily married for over a decade and is the proud mother of 4 young boys who keep life
                      interesting with their bikes, tennis, drums, and fencing.
                  </p>
              </div>
            </div>
          </div>


          <div className="section-container text-center">
            <div className="small-12 columns">
              <p className="hero-title">Team</p>

              <div className="row" >
                  <div className="team-member small-12 medium-5 columns">
                      <img src="/static/img/team/lia.png" className="team-icon"/>
                      <p className="team-member-name">Lia</p>
                      <p className="team-member-job">Coaching</p>
                      <hr className='divider'/>

                      <div className="section-text">
                        <p>Lia brings a background in Psychology and years of experience working as a marriage educator. Married for
                            eight years, Lia knows first hand how powerful relationship skills are in creating a healthy and lasting
                            partnership. She is passionate about this incredibly unique and innovative way to teach couples to have
                            a thriving marriage. When she’s not saving marriages, Lia spends her time raising her two boys alongside
                            her husband and pursuing her other passion, ceramic arts.</p>
                      </div>
                  </div>

                  <div className="team-member small-12 medium-5 columns">
                      <img src="/static/img/team/linda.png" className="team-icon"/>
                      <p className="team-member-name">Linda</p>
                      <p className="team-member-job">Coaching</p>
                      <hr className='divider'/>

                      <div className="section-text">
                          <p>Linda has a background in Social Work and Education. She has been a Power of Two coach since early 2014.
                              She loves being able to help members apply the Power of Two skills in their relationships and to share
                              the insights garnered from having been married since 1980. Now, “empty nesters,” she and her husband
                              remain avid hikers and have surpassed their goal of going on over 1,000 hikes together that they set as
                              newlyweds.</p>
                      </div>

                  </div>
              </div>


              <div className="row padding-top-75">
                  <div className="team-member small-12 medium-5 columns">
                      <img src="/static/img/team/jacob.png" className="team-icon"/>
                      <p className="team-member-name">Jacob</p>
                      <p className="team-member-job">Co-Founder, Product</p>
                      <hr className='divider'/>

                      <div className="section-text">
                          <p>Jacob believes that creating happy healthy relationships is a skill that can be taught and practiced.
                              He’s passionate about applying technology to improve people’s lives. Both prior to co-founding Power
                              of Two and since, he’s contributed to both financial and consumer web technology companies, largely
                              as a product manager. He holds an MBA from UC Berkeley's Haas School of Business and an
                              undergraduate degree in Applied Math from Yale University. He lives in Berkeley California with his
                              loving wife and 2 small kids. When he does sneak away, he tries to spend as much time as possible
                              outside, preferably in the wilderness.</p>
                      </div>

                  </div>

                  <div className="team-member small-12 medium-5 columns">
                      <img src="/static/img/team/jesse.png" className="team-icon"/>
                      <p className="team-member-name">Jesse</p>
                      <p className="team-member-job">Co-Founder, Engineering</p>
                      <hr className='divider'/>

                      <div className="section-text">
                          <p>Jesse is passionate about dynamic, fast moving companies and the technology that enables them. Jesse both
                              leads and contributes to developing the technology of Power of Two and is a founding partner. A
                              relentless problem solver, he makes sure that our tech doesn't slow us down. Jesse holds a bachelor's
                              degree in Computer Science from Yale University, and Torque is the 3rd business he's helped found. He is
                              happily married with 3 children.</p>
                      </div>

                  </div>
              </div>


              <div className="row padding-top-75">
                  <div className="small-12 medium-5 columns">
                      <img src="/static/img/team/daniel.png" className="team-icon"/>
                      <p className="team-member-name">Daniel</p>
                      <p className="team-member-job">Co-Founder, Creative</p>
                      <hr className='divider'/>

                      <div className="section-text">
                          <p>Daniel is an actor, writer, director and founding partner at Power of Two. His comedy shorts and web
                              series have been featured on Funny or Die, College Humor, The Huffington Post, and Gawker, and he has
                              appeared on television on ABC, TBS and the History Channel. He has developed an original sitcom for eOne
                              Television, and performs improvisational comedy at the Upright Citizens Brigade theater in Los Angeles.
                              He enjoys eating super-spicy meals and exploring Los Angeles with his beautiful screenwriter wife, Katie
                              and their 2-year old daughter. He holds a bachelor's degree in English and Theater Studies from Yale
                              University.</p>
                      </div>

                  </div>

                  <div className="small-12 medium-5 columns">
                      <img src="/static/img/team/clay.png" className="team-icon"/>
                      <p className="team-member-name">Clayton</p>
                      <p className="team-member-job">Engineering</p>
                      <hr className='divider'/>

                      <div className="section-text">
                          <p>Clayton is a senior designer and user experience expert. One of the original members of the Power of Two
                              team, Clayton worked to create the fun and engaging model we use to teach relationship skills. Clayton
                              holds a BFA in sculpture from The University of Kansas. He lives in Northern California with his lovely
                              wife and their two children.</p>
                      </div>

                  </div>
              </div>

          </div>


          </div>
      </div>

      <div class="about-program-end-section section">
        <div className='section-wrapper'>
            <div class="row">
              <hr className='divider'/>
            </div>
            <div class="row end-text">
                <p class="text-center big-bold">
                    Dr. Susan Heitler, PhD developed Power of Two as a result of 20+ years of helping hundreds of couples in her
                    private practice remember why they chose to marry their spouse in the first place. She believes deeply that
                    great therapy isn’t a permanent crutch… it should teach people the skills to walk on their own feet, lovingly,
                    together.
                </p>
            </div>
            <div class="row">
              <hr className='divider'/>
            </div>
        </div>
      </div>

      <div className='about-program-signup-section section'>
        <div class="text-center">
            <Link to={{pathname: "/signup/", from: "about" }}>
              <img src='/static/img/arrow_button.png' className='about-signup' alt='Signup'/>
            </Link>

            <p class="big-bold">Sign up today!</p>
        </div>
      </div>
    </>
    )
  }
}

export default Content
