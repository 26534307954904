import React, { Component } from 'react'
import TopHeader from '../../Header/component/TopHeader.js'
import Footer from '../../Footer/component/Footer.js'
import Content from './Content'
import Base from '../../Common/component/Base.js'
import { Helmet } from 'react-helmet';
import {Content as ContentContainer} from '../../Main/container/Content'

export class Home extends Base {
  render(){
    return (
      <div id='home'>
        <Helmet>
          <title>Marriage Counseling-Online alternative | more private, more affordable : Power of Two Marriage</title>
          <meta name="description" content="Get 3 Days of Free Help (alone or with your partner). Discover the best alternative to marriage counseling-online. Fight less. Build communication & love." />
        </Helmet>

        <TopHeader />
        <ContentContainer component={Content} />
        <Footer />
      </div>
    )
  }
}

export default Home
