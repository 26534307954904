import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Content extends Component {
  render() {
    return (
      <section class="row rhythm-top" id="main-section">
        <div id="full_column">
          <h1>Media</h1>

          <div class="featured-video">
            <iframe
              class="sproutvideo-player"
              type="text/html"
              src="https://videos.sproutvideo.com/embed/7c98d2b51117eac4f4/a4fd3cc97e268b05?type=sd"
              width="420"
              height="236"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <p>
              <i>Watch a brief video of how the program works.</i>
            </p>
            <p>
              The team at Power of Two has received extensive accolades from the
              press. All media inquiries should be directed to:
            </p>
            <p>
              Jesse Heitler,
              <br /> 303.725.8558 <br />
              <a href="mailto:info@poweroftwomarriage.com">
                info@poweroftwomarriage.com
              </a>
            </p>
            <a
              class="landing-anchor"
              href="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/po2-press-kit-final.pdf?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=wO%2BCz%2B%2BjgwxVlzeRMT8VYWhojV8%3D&amp;Expires=1674477959"
            >
              Download the Power of Two press kit.
            </a>
          </div>
          <div id="boiler-plate">
            <h2>About Power of Two </h2>
            <p>
              In 2010, Power of Two released{" "}
              <b>
                the most innovative and cost-efficient web-based marriage and
                relationship health program on the market
              </b>
              , offering individuals and couples a fun and effective way to
              learn key relationship skills online, with the support of a
              personal relationship coach.
            </p>
            <p>
              <b>
                Federally funded by the Department of Health and Human Services
              </b>
              , Power of Two was developed by renowned psychologists Dr. Susan
              Heitler and Dr. Abigail Hirsch (mother and daughter team) and
              several other Heitler family members,{" "}
              <b>designed for good people who want great relationships.</b>
            </p>
            <p>
              A robust marriage/relationship education program, Power of Two
              offers videos, interactive exercises, games, fun quizzes, podcasts
              and worksheets for couples that want to improve their
              communication skills and make their relationships stronger. Best
              yet,{" "}
              <b>
                Power of Two is designed to do at your own pace, with or without
                your partner, in the privacy of your own home, with help from
                your very own personal, yet anonymous, relationship coach
              </b>
              . It’s convenient, accessible, affordable, private, and fun...and
              it works.
            </p>
            <p>
              Power of Two leverages the potency of technology and expert
              relationship skills to create “<b>short bites</b>” of easily
              digestible information. Then, the program guides members as they
              apply what they’ve learned to their real life. For more
              information visit poweroftwomarriage.com.
            </p>
          </div>

          <div id="media-links">
            <a name="articles" />
            <h1>Dr. Susan Heitler and the Power of Two in the news</h1>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://mikeyoungcoach.com/a-review-of-power-of-two-marriage-website/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/mikeyoung.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=RZLVfhybLweipGErzbDzj9RUAxo%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Mike Young Financial Coaching <span>June 23, 2011</span>
                </h2>
                <a
                  href="http://mikeyoungcoach.com/a-review-of-power-of-two-marriage-website/"
                  target="_blank"
                >
                  A Review of Power Of Two Marriage Website
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://abcnews.go.com/Health/w_RelationshipNews/relationships-long-commutes-toll-marriage/story?id=13719087"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/abc.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=h2woCSy0dEsO8wQ%2BjVFxjNSYdr8%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  abcnews.com <span>May 31st, 2011</span>
                </h2>
                <a
                  href="http://abcnews.go.com/Health/w_RelationshipNews/relationships-long-commutes-toll-marriage/story?id=13719087"
                  target="_blank"
                >
                  Long Commutes Can Drive Couples Apart
                </a>
                <ul>
                  <h3>Additional Links:</h3>
                  <li>
                    <a
                      href="http://abcnews.go.com/Health/women-cheating-men-study/story?id=13885519"
                      target="_blank"
                    >
                      Women Cheating Men
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://abcnews.go.com/Health/SleepDisorders/sleepless-nights-linked-marital-strife/story?id=13814584"
                      target="_blank"
                    >
                      Sleepless Nights & Marital Strife
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://abcnews.go.com/Health/relationships-breaking-hurt-physically/story?id=13238521"
                      target="_blank"
                    >
                      Relationships Breaking Hurts Physically
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.lhj.com/relationships/can-this-marriage-be-saved/sex/i-cant-stand-sex/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/ladies.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=q8nehzBK93yWyAGW%2BwumDm0BQtI%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Ladies Home Journal <span>May, 2011</span>
                </h2>
                <a
                  href="http://www.lhj.com/relationships/can-this-marriage-be-saved/sex/i-cant-stand-sex/"
                  target="_blank"
                >
                  Can This Marriage Be Saved?
                </a>
                <ul>
                  <h3>Additional Links:</h3>
                  <li>
                    <a
                      href="http://www.lhj.com/relationships/marriage/challenges/relationship-qa-hiding-credit-card-debt/"
                      target="_blank"
                    >
                      Can This Marriage Be Saved? (Hiding Credit Card Debt)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.lhj.com/relationships/marriage/challenges/relationship-qa-he-makes-me-feel-guilty-about-shopping/"
                      target="_blank"
                    >
                      Can This Marriage Be Saved? (Feeling Guilty About
                      Shopping)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.lhj.com/relationships/marriage/challenges/our-kids-drove-us-crazy/"
                      target="_blank"
                    >
                      Can This Marriage Be Saved? (Our Kids Drove Us Crazy)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.lhj.com/relationships/marriage/challenges/relationship-qa-i-want-him-to-have-a-steady-income/"
                      target="_blank"
                    >
                      Can This Marriage Be Saved? (I Want Him to Have a Steady
                      Income)
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.lhj.com/relationships/can-this-marriage-be-saved/work-money/he-lost-his-job/"
                      target="_blank"
                    >
                      Can This Marriage Be Saved? (He Lost His Job)
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://psychcentral.com/lib/2011/5-communication-pitfalls-and-pointers-for-couples/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/psychcentral.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=ykcVhGuZ1lPaCddpX4OlGZ3t9l0%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  PychCentral <span>March 9th, 2011</span>
                </h2>
                <a
                  href="http://psychcentral.com/lib/2011/5-communication-pitfalls-and-pointers-for-couples/"
                  target="_blank"
                >
                  5 communication pitfalls for couples.
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.assumelove.com/2011/02/power_of_two_online_marriage_e.html"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/assumelove.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=%2Btr4KNKiuZ55OKY1xhPSrtF3b18%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Assume Love <span>February 10, 2011</span>
                </h2>
                <a
                  href="http://www.assumelove.com/2011/02/power_of_two_online_marriage_e.html"
                  target="_blank"
                >
                  Power of Two: Online Marriage Education
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://awtr.blogspot.com/2011/02/awtr-show-301-power-of-two-marriage.html"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/armywife.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=84AaVgrU1CIkp2lx9l57Wh33c84%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Army Wife Talk Radio <span>February 7, 2011</span>
                </h2>
                <a
                  href="http://awtr.blogspot.com/2011/02/awtr-show-301-power-of-two-marriage.html"
                  target="_blank"
                >
                  Interview of Dr. Abigail Hirsch
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://themommybrand.com/blog/marriage/review-and-giveaway-power-of-two-marriage-enrichment/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/mommybrand.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=HE2AnOn4tIAvef%2BdEQcK%2FQeEaEU%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  The Mommy Brand <span>January 7, 2011</span>
                </h2>
                <a
                  href="http://themommybrand.com/blog/marriage/review-and-giveaway-power-of-two-marriage-enrichment/"
                  target="_blank"
                >
                  Review (and Giveaway!): Power of Two Marriage Enrichment
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://nashvillemarriagestudio.com/blog/communication/marriage-help-theres-an-app-for-that-giveaway-inside/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/nashville.jpeg?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=vbO9IqInuQwdaY8gALvenTlzDXs%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Nashville Marriage Studio <span>January, 2011</span>
                </h2>
                <a
                  href="http://nashvillemarriagestudio.com/blog/communication/marriage-help-theres-an-app-for-that-giveaway-inside/"
                  target="_blank"
                >
                  Marriage Help… there’s an app for that
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://itstwinsanityreviews.blogspot.com/2011/01/power-of-two-marriage-program.html"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/twinsanity.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=YWfphrgpW2CVlf6W1OYuYdTOCwc%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  It's Twinsanity <span>January 27, 2011</span>
                </h2>
                <a
                  href="http://itstwinsanityreviews.blogspot.com/2011/01/power-of-two-marriage-program.html"
                  target="_blank"
                >
                  Power of Two Marriage Program
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.sheknows.com/parenting/articles/820750/Tricks-kids-use-to-get-their-way"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/parenting.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=Iz8oQfzMJhdIUVaEGYx122%2BUQN8%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  SheKnows Parenting <span>December 3, 2010</span>
                </h2>
                <a
                  href="http://www.sheknows.com/parenting/articles/820750/Tricks-kids-use-to-get-their-way/"
                  target="_blank"
                >
                  Tricks Kids Use to Get Their Way: Resist Manipulation
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/parents.gif?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=TQCqPsovuTQJqH%2FctxNEBX5V50c%3D&amp;Expires=1674477959" />
              </div>
              <div class="media-link">
                <h2>
                  Parents <span>December 3, 2010</span>
                </h2>
                <p>How to Fight in Front of the Kids</p>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://liveloveleslie.com/2010/12/15/website-review-the-power-of-two-marriage-program/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/liveloveleslie.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=Gg1LrH5%2BcnTCFwbo6v4pJMnmt4I%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Live Love Leslie <span>December, 2010</span>
                </h2>
                <a
                  href="http://liveloveleslie.com/2010/12/15/website-review-the-power-of-two-marriage-program/"
                  target="_blank"
                >
                  Website Review: The Power of Two Marriage Program
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://lifegems4marriage.com/2010/11/26/web-based-marriage-skills-training-is-affordable-private/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/lorilowe.jpeg?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=quaZpeaOmQTvfMIWJgM4P0NVMws%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  <span>Marriage Gems </span>November 26, 2010
                </h2>
                <a
                  href="http://lifegems4marriage.com/2010/11/26/web-based-marriage-skills-training-is-affordable-private/"
                  target="_blank"
                >
                  Web-Based Marriage Skills Training is Affordable & Private
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.projecthappilyeverafter.com/2010/10/how-to-anger-proof-your-marriage/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/everafter.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=91UsUQBJxwkkIYWT99uxNwohYV8%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Project Happily Every After <span>October 11, 2010</span>
                </h2>
                <a
                  href="http://www.projecthappilyeverafter.com/2010/10/how-to-anger-proof-your-marriage/"
                  target="_blank"
                >
                  How to anger-proof your marriage
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.empowher.com/mental-health/content/how-get-over-depression-after-breakup?page=0,1"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/empower.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=hWj2VtVWBuHzucxGjptDU%2BR85yM%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  EmpowHer.com <span>June 17, 2010</span>
                </h2>
                <a
                  href="http://www.empowher.com/mental-health/content/how-get-over-depression-after-breakup?page=0,1"
                  target="_blank"
                >
                  How to Get Over Depression After a Breakup
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.9news.com/news/story.aspx?storyid=140245&catid=222"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/9news.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=HiRB3aPaUdoZFQ3whiPxcYuVCnM%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  9News.com (NBC) <span>June 2, 2010</span>
                </h2>
                <a
                  href="http://www.9news.com/news/story.aspx?storyid=140245&catid=222"
                  target="_blank"
                >
                  Mother accused of killing baby son set to appear in court
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://cosmo.intoday.in/cosmopolitan/story.jsp?sid=7266"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/cosmo.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=iUpWC2FBqTQLr%2FkBfX2BCIpaveI%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Cosmopolitan <span>April, 2010</span>
                </h2>
                <a
                  href="http://cosmo.intoday.in/cosmopolitan/story.jsp?sid=7266"
                  target="_blank"
                >
                  Non mushy moves he'll dig
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.denverpost.com/news/ci_13922615"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/dpost.jpeg?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=17qreajwFkLrhSTQn7EzmW8bMmI%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  The Denver Post <span>December 4, 2009</span>
                </h2>
                <a
                  href="http://www.denverpost.com/news/ci_13922615"
                  target="_blank"
                >
                  DPS board, superintendent get coaching on making up
                </a>
                <ul>
                  <h3>Additional Links:</h3>
                  <li>
                    <a
                      href="http://www.denverpost.com/headlines/ci_13546193"
                      target="_blank"
                    >
                      Surviving Infidelity: After David Letterman's confession
                      to sexual affairs, you have to wonder: Is there hope for
                      recovery in such devastating relationship wreckage?
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.details.com/sex-relationships/marriage-and-kids/200806/a-lifeless-marriage-does-not-have-to-mean-divorce#ixzz182UhkeNp"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/details.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=uk4fe6zoWp64SKD4U5DdJdXuTbw%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Details <span>October 20, 2009</span>
                </h2>
                <a
                  href="http://www.details.com/sex-relationships/marriage-and-kids/200806/a-lifeless-marriage-does-not-have-to-mean-divorce#ixzz182UhkeNp"
                  target="_blank"
                >
                  Your Marriage Isn't Dead-It's Comatose
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.webmd.com/sex-relationships/features/unhealthy-marriage-signs-and-finding-help"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/webmd.gif?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=h45FXwUNjZXHhPgC1sbsuKsxJas%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  WebMD <span>2009</span>
                </h2>
                <a
                  href="http://www.webmd.com/sex-relationships/features/unhealthy-marriage-signs-and-finding-help"
                  target="_blank"
                >
                  Is Your Marriage Toxic?
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.milspouse.com/dealing-with-inlaws.aspx"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/milspouse.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=qETDSjN1BFZ71QljJFMP3ruMeMw%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  MilSpouse.com <span>December, 2008</span>
                </h2>
                <a
                  href="http://www.milspouse.com/dealing-with-inlaws.aspx"
                  target="_blank"
                >
                  Dealing w/In-Laws: Setting boundaries and other gems of wisdom
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.oprah.com/omagazine/The-Gaslight-Effect-Is-He-Playing-with-Your-Mind"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/oprah.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=CFskcBnArtuQZsMcZADRr8R9L2Y%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  O Magazine <span>June, 2007</span>
                </h2>
                <a
                  href="http://www.oprah.com/omagazine/The-Gaslight-Effect-Is-He-Playing-with-Your-Mind"
                  target="_blank"
                >
                  The Gaslight Effect: Is He Playing with Your Mind?
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://origin.www.womenshealthmag.com/sex-and-relationships/6-things-to-consider-before-shacking-up?page=2"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/womenshealth.gif?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=4MuCxXuVoXT9XSgnj%2FvDOU9Td5w%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Women's Health <span>June, 2006</span>
                </h2>
                <a
                  href="http://origin.www.womenshealthmag.com/sex-and-relationships/6-things-to-consider-before-shacking-up?page=2"
                  target="_blank"
                >
                  Living in Sin: Unwedded Bliss
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.psychotherapy.net/interview/susan-heitler"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/psychtherapy.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=ymlnsmdoYGBkEA%2BYXti5%2FQHwtQU%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Pychotherapy.net <span>January, 2006</span>
                </h2>
                <a
                  href="http://www.psychotherapy.net/interview/susan-heitler"
                  target="_blank"
                >
                  An Interview with Susan Heitler, PhD
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.fitnessmagazine.com/health/spirit/your-best-you/why-we-apologize-too-much-and-how-to-stop/?page=2"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/fitness.gif?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=S5i2m5vKnvWQiNn7OQp5tfnuESo%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Fitness <span>2005</span>
                </h2>
                <a
                  href="http://www.fitnessmagazine.com/health/spirit/your-best-you/why-we-apologize-too-much-and-how-to-stop/?page=2"
                  target="_blank"
                >
                  Why We Apologize Too Much, and How to Stop
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="link: http://www.familycircle.com/family-fun/relationships/4-ways-to-love-the-one-youre-with/?page=2"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/familycirc.gif?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=b23ncptJLHJF0N%2FrBUY%2BW6blLxk%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>Family Circle </h2>
                <a
                  href="link: http://www.familycircle.com/family-fun/relationships/4-ways-to-love-the-one-youre-with/?page=2"
                  target="_blank"
                >
                  4 Ways to Love the One You're With
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.menshealth.com/sex-women/do-you-have-cheating-wife-or-girlfriend"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/mens.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=jH2rchhCxu%2BKam17uhwekpsOS%2B4%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Mens Health <span>August 26, 2004</span>
                </h2>
                <a
                  href="http://www.menshealth.com/sex-women/do-you-have-cheating-wife-or-girlfriend"
                  target="_blank"
                >
                  6 Signs She Might Be Cheating...or perhaps thinking about it
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.cbsnews.com/video/watch/?id=615383n&tag=related;photovideo"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/earlyshow.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=06x9Ynx7LnN0xADALabWLTAddNM%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  CBS - The Early Show <span>June 9, 2004</span>
                </h2>
                <a
                  href="http://www.cbsnews.com/video/watch/?id=615383n&tag=related;photovideo"
                  target="_blank"
                >
                  Harry Smith describes Dr. Heitler as "the most influential
                  person in my life — my therapist.
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.rd.com/family/14-ways-resolve-conflicts-and-solve-relationship-problems/"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/readers.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=YewFyECD7jYkPw8cfplsfPzXAFI%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>Readers' Digest </h2>
                <a
                  href="http://www.rd.com/family/14-ways-resolve-conflicts-and-solve-relationship-problems/"
                  target="_blank"
                >
                  14 Ways to Resolve Conflicts and Solve Relationship Problems
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.psychologytoday.com/articles/200305/married-money"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/psychtoday.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=U26DHWPtmUpCFFy0SIO99hhtO%2FM%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  Pychology Today <span>May 29, 2003</span>
                </h2>
                <a
                  href="http://www.psychologytoday.com/articles/200305/married-money"
                  target="_blank"
                >
                  Married Money: Money matters are a leading cause of family
                  conflict. Couples often don't see eye to eye.
                </a>
              </div>
            </div>

            <div class="media-unit">
              <div class="media-logo">
                <a
                  href="http://www.foxnews.com/story/0,2933,956,00.html"
                  target="_blank"
                >
                  <img src="https://winwinprod-sitemedia.s3.amazonaws.com/static/img/media_page/foxnews.png?AWSAccessKeyId=0R7M0766WXZT9YZQV5G2&amp;Signature=4ZwhlnHTOe2ncAsrxynUzhuLtI8%3D&amp;Expires=1674477959" />
                </a>
              </div>
              <div class="media-link">
                <h2>
                  FoxNews.com <span>March 7, 2001</span>
                </h2>
                <a
                  href="http://www.foxnews.com/story/0,2933,956,00.html"
                  target="_blank"
                >
                  Love After the Fight
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Content;
