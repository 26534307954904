import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import NewsletterSubscribeForm from "../NewsletterSubscribeForm";
import { getValueFromLocalStorage } from "../../utils/localstorage";
import { useFlags } from "launchdarkly-react-client-sdk";

const SHOW_FORM_DELAY = 10000;

const WithSubscribeForm = (Component) => {
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(true);
  const isUserAlreadySubscribed = getValueFromLocalStorage();
  const {po2RitualSubscribeForm} = useFlags();

  useEffect(() => {
    if(!po2RitualSubscribeForm) return;

    const timeout = setTimeout(() => {
      setIsEmailSubmitted(false);
    }, SHOW_FORM_DELAY);

    return () => {
      clearTimeout(timeout);
    };
  }, [po2RitualSubscribeForm]);

  useEffect(() => {
    if (!isUserAlreadySubscribed && !isEmailSubmitted)
      document.body.style.position = "fixed";

    return () => {
      document.body.style.position = "";
    };
  }, [isEmailSubmitted]);

  return () => (
    <>
      {!isEmailSubmitted && !isUserAlreadySubscribed && (
        <NewsletterSubscribeForm onSubmit={() => setIsEmailSubmitted(true)} />
      )}
      <Component />
    </>
  );
};

const RouteGuard = (props) => {
  return <Route {...props} component={WithSubscribeForm(props.component)} />;
};

export default RouteGuard;
