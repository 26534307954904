export const getPath = () => {
  const pathname = window.location.pathname;
  let path = pathname.substring(1, pathname.length).replace("/", "_");
  if (pathname.length <= 1) path = "home";
  return path;
};

export const redirectToRitual = ({url, utm_campaign, utm_medium}) => {
  const funnel_start = url.includes("pricing") ? "pricing" : "getstarted";
  const anonId = window?.analytics?.user()?.anonymousId();
  let href = `${url}?funnelid=tiers-v1&funnel_start=${funnel_start}&utm_source=po2&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}&member_funnel=strp`;
  if(anonId){
    href+=`&uuid=${anonId}`
  }
  window.location.href = href;
}