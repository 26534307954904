import React from "react";
import arrow from "../assets/arrow.svg";
import lines from "../assets/lines.png";

const LearnMoreButton = ({className}) => {
  return (
    <div className={`learn_more ${className}`}>
      <div className="learn_more__button">
        Learn more
      </div>
    </div>
  );
};

export default LearnMoreButton;
