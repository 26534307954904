import React, { Component } from 'react'
import TopHeader from '../../Header/component/TopHeader.js'
import Footer from '../../Footer/component/Footer.js'
import Content from './Content'
import Base from '../../Common/component/Base.js'
import { Helmet } from 'react-helmet';


export class Faq extends Base {
  render(){
    return (
      <div id='faq'>
        <Helmet>
          <title>Frequently Asked Questions : Power of Two Marriage</title>
          <meta name="description" content="Po2 teaches Powerful Techniques to Fix Broken Relationships. Start by yourself. Affordable, online, with a real coach. Build communication, trust & intimacy." />
        </Helmet>

        <TopHeader />
        <Content />
        <Footer />
      </div>
    )
  }
}

export default Faq
