import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useAddButtonId = () => {
 const location = useLocation();

  useEffect(() => {
    const ctaButtons = document.querySelectorAll("a[href*='signup'],a[href*='landing/intro']");
    ctaButtons.forEach(button => {
        button.id = "GetStarted";
    })
  }, [location.pathname]);
};

export default useAddButtonId;
