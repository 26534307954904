import React, { Component } from 'react'

export class SatisfactionGuarantee extends Component {
  render (){
    return (
      <div
        id='satisfaction_guarantee'
        className='satisfactionPopup'
      >
          <div
            style={{
              fontSize: '1.2em',
              padding: '5px 5px 5px 15px',
              borderBottom: '1px solid #000'
            }}
          >
            Satisfaction Guarantee
          </div>

          <div
            style={{
              padding: '15px',
              lineHeight: '1.3em'
            }}
          >
          <p>We are honored to be your partner in improving your relationship. And, we know that when people make Power of Two Online part of their weekly routine,
          great things happen. That said, sometimes people don't use Power of Two Online, and, as a result don't get great results.
          </p>
          <br/>
          <p>
          Our <strong>Satisfaction Guarantee</strong> helps us to help you. Simply put,
          if you are messaging with your coach and completing activities at least once a week each week in a given month, and you are not happy with the results, we will happily credit that month's membership fee. Just ask your coach!
          </p>

          </div>
          <div onClick={this.props.onClose} style={{ cursor: 'pointer', margin: 'auto', color: '#fff', border: '1px solid #000', backgroundColor: 'green', width: '100px', borderRadius: '10px' }}>OK</div>
      </div>
    )
  }
}

export default SatisfactionGuarantee
