import React, { useEffect } from "react";

const Coach = () => {
  useEffect(() => {
    window.location.replace("https://www.po2.com/coach");
  }, []);

  return <div></div>;
};

export default Coach;
