import axios from "axios";

const getHeaders = (headers) => {
  const token = localStorage.getItem("token");

  if (!headers) {
    headers = {
      "Content-Type": "application/json",
    };
  }

  if (token) {
    headers.Authorization = `JWT ${token}`;
  }

  return headers;
};

export const getBaseUrl = () => {
  return (
    process.env.REACT_APP_BASE_URL || "https://www.po2.com/"
  );
};

export const baseUrl = getBaseUrl();

export const getBaseApiUrl = () => {
  return `${baseUrl}api/v1`;
};

export const baseApiUrl = getBaseApiUrl();

const methods = {
  post: function (url, data) {
    return axios({
      url: `${baseApiUrl}${url}`,
      method: "post",
      responseType: "json",
      headers: getHeaders(),
      data,
    });
  },
  get: function (url) {
    return axios({
      url: `${baseApiUrl}${url}`,
      method: "get",
      responseType: "json",
      headers: getHeaders(),
    });
  },
};

export function login(username, password) {
  return methods.post("/login/", {
    username,
    password,
  });
}

export function signup(username, email, password) {
  return methods.post("/signup/", {
    username,
    email,
    password,
  });
}

export function statusLogin() {
  return methods.get("/login/");
}

export function planList() {
  return methods.get("/plan/");
}

export function planLink(handle) {
  return methods.get(`/plan/${handle}/`);
}

export function recordSession(query) {
  return methods.post("/record/session/", {
    query: query,
  });
}

export function recordFullService(data) {
  return methods.post("/record/options/", {
    options: data,
  });
}

export function updateSession(key, value) {
  return methods.post("/update/session/", {
    key: key,
    value: value,
  });
}

export function newsletterSignup(email) {
  return methods.post("/newsletter/", {
    email: email,
  });
}

export default methods;
