import React from "react";
import { Link } from "react-router-dom";

const InfoLeft = ({ data }) => {
  return (
    <div className="info-left">
      <div className="info-left-head">
        <p className="info-left-head-title-1">
          Chat with your relationship coach <b>today.</b>
        </p>
        <p className="info-left-head-title-2">
          The online alternative to marriage counseling
        </p>
      </div>
      <div className="info-left-head-mobile">
        <div>
          <p className="text1">
            Chat with your relationship coach <b>today.</b>
          </p>
          <p className="text2">The online alternative to marriage counseling</p>
          <div className="links">
            <Link to="/signup" className="signup">
              get started
            </Link>
            <Link to="/landing/intro" className="intro">
              Take our relationship quiz →
            </Link>
          </div>
        </div>
      </div>
      <div className="info-left-article">
        <h1>{data.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.article_html }}></div>
        <Link to="/signup/" className="info-left-article-talk-with">
          Talk with your relationship coach today.
        </Link>
      </div>
      <div className="info-left-faq">
        <div dangerouslySetInnerHTML={{ __html: data.faq }}></div>
      </div>
      <div className="info-left-links">
        <div dangerouslySetInnerHTML={{ __html: data.info_links }}></div>
      </div>
    </div>
  );
};

export default InfoLeft;
