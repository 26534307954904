import React from "react";
import ritualLogo from "../assets/ourritual_extended_logo.svg";
import po2Logo from "../assets/po2_logo.png";

const Logo = ({ className }) => {
  return (
    <div className={`logo ${className}`}>
      <img src={ritualLogo} alt="ritual_logo" />
      <p>&</p>
      <img src={po2Logo} alt="po2_logo" />
    </div>
  );
};

export default Logo;
