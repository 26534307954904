import React, { Component } from 'react'

import { Link } from "react-router-dom"


export class Plans extends Component {

  openDescription = (e, plan) =>{
    const ele = document.getElementById(plan);
    ele.style.display = (ele.style.display === 'none' || ele.style.display === '') ? 'block' : 'none';

    e.target.innerHTML = e.target.innerHTML === '+' ? '--' : '+'
  }

  render(){
    const { signedUp } = this.props

    return (
      <>
        <div className='pricing-title'>{signedUp ? "You're Almost There!" : 'Fix Your Marriage'}</div>
        <div className='pricing-sub-title'>
        { signedUp ? "Time to pick a plan." : ''} Become a member today and start chatting with your coach today.
        </div>
        <div id='price-table'>

          <div className='price-table-column' id='helping-hand'>
            <div className='price-table-column-head helping-hand'>
              <div className='price-table-column-title'>Helping Hand</div>
              <div className='price-table-column-price'>$36/month</div>
              <div className='price-table-column-note'>No Annual Commitment</div>
              <button className='price-table-start-trial' onClick={this.props.helpingHand}>GET STARTED NOW</button>
              <div className='price-table-open-more' onClick={((e) => this.openDescription(e, 'helping-hand-description'))}>+</div>
            </div>
            <div className='price-table-column-content helping-hand' id='helping-hand-description'>
              <ul>
                <li><strong>Unlimited</strong> expert<br/> coaching conversations<br/>(in-app messaging)</li>
                <li>24/7 access to <br/><strong>all learning modules</strong></li>
                <li>Renewed <strong>monthly</strong></li>
                <li>Cancel <strong>anytime</strong></li>
              </ul>
            </div>
          </div>

          <div className='price-table-column' id='marriage-saver'>
            <div id='most-popular'>Most Popular</div>
            <div className='price-table-column-head marriage-saver'>
              <div className='price-table-column-title'>Marriage Saver</div>
              <div className='price-table-column-price'>$20/month</div>
              <div className='price-table-column-note'>Billed $240 Annually</div>
              <button className='price-table-start-trial' onClick={this.props.marriageSaver}>GET STARTED NOW</button>
              <div className='price-table-open-more' onClick={((e) => this.openDescription(e, 'marriage-saver-description'))}>+</div>
            </div>
            <div className='price-table-column-content marriage-saver' id='marriage-saver-description'>
              <ul>
                <li><strong>Unlimited</strong> expert<br/> coaching conversations<br/>(in-app messaging)</li>
                <li>24/7 access to <br/><strong>all learning modules</strong></li>
                <li>Renewed <strong>annually</strong></li>
                <li><strong>100% Money Back Guarantee</strong><br/>(*with minimum weekly engagement requirements)</li>
              </ul>
            </div>
          </div>

        </div>

        <div className='pricing-header'>Not ready to become a member? Sign up for our <strong>free</strong> email tips series <Link to='/newsletter/'>HERE</Link></div>
      </>
    )
  }
}

export default Plans
