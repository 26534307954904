import React from "react";
import { getPath, redirectToRitual } from "../../utils";

const BannerWrapper = ({ children }) => {
  const onClick = () => {
    const path = getPath();
    redirectToRitual({
      url: "https://www.ourritual.com",
      utm_campaign: path,
      utm_medium: "banner",
    });
  };

  return (
    <div onClick={onClick} className="banner__wrapper" id="GetStartedBanner">
      {children}
    </div>
  );
};

export default BannerWrapper;
