import React, { Component } from 'react'
import { recordFullService} from '../../Api/Client'


export class FullService extends Component {
  state = {
    beginTrial: false,
    optionList: [
      {'id': 'option1', 'value': '45-minute kick-off video sessions with your coach', 'checked': false},
      {'id': 'option2', 'value': 'Power of Two Book and Workbook', 'checked': false},
      {'id': 'option3', 'value': '3 x moderated coaching conversations (15 minutes each)', 'checked': false},
      {'id': 'option4', 'value': 'Alexa heated conversation monitoring', 'checked': false},
      {'id': 'option5', 'value': 'Anytime 5-minute coaching phone check-in (up to 10/year)', 'checked': false},
    ]
  }

  handleBeginTrial = () => {
    this.props.scrollToTop()

    this.setState({
      beginTrial: true
    })

    const chosenOptionList = []

    this.state.optionList.forEach((item) => {
      if(item.checked){
        chosenOptionList.push(item.id)
      }
    })

    recordFullService(chosenOptionList)
  }

  handleOptionClick = (value) => {
    const option = this.state.optionList.find((item) => item.id === value)

    option.checked = !option.checked

    this.setState({
      'optionList': this.state.optionList
    })
  }

  goBack = () => {
    this.props.goBack()
  }

  render(){
    const { beginTrial } = this.state

    return (
      <>
      {
        beginTrial ?
          <div>
            <p className='sorryMessage'>
            Thank you for your interest in these premium features!
            We are sorry to report that are no longer available at this time.
            We are sorry for the inconvenience.
            We’d like to offer you a <strong>10%</strong> discount on the Marriage Saver plan if you’d still like to sign up.
            Click below to sign up, and user offer code <strong>LOVE</strong>.
            </p>

            <div
              className='button go-back-button'
              onClick={this.props.startFreeTrial}
            >
              Start Today
            </div>
          </div>
        :
        <>
          <h1>Welcome to the Full Service Marriage Coach</h1>
          <h3>Please check the special features you’d like to sign up for: [NOTE: can select 0-5 options]</h3>
          <ul className='ul-style'>
            {
              this.state.optionList.map(
                (item) => {
                  return (
                    <li className='full-service-option' key={item.id}>
                      <input
                        type='checkbox'
                        onChange={(e) => this.handleOptionClick(item.id)}
                        value={item.id}
                        checked={item.checked ? 'checked' : ''}
                        id='option${item.id}'
                      />
                        <span onClick={(e) => this.handleOptionClick(item.id)}>{item.value}</span>
                    </li>
                  )
                }
              )
            }
          </ul>

          <div className='button begin-trial-button' onClick={this.handleBeginTrial}>Start Today »</div>
          <div className='button change-plan-button' onClick={this.goBack}>Pick a different plan »</div>
        </>
      }
      </>
    )
  }
}

export default FullService
