const NEWSLETTER_SUBSCRIBED_KEY = "ritual_newsletter_subscribed";

export const getValueFromLocalStorage = () => {
  try {
    return localStorage.getItem(NEWSLETTER_SUBSCRIBED_KEY);
  } catch (error) {
    return false;
  }
};

export const setNewsLetterSubscribed = () => {
  try {
    localStorage.setItem(NEWSLETTER_SUBSCRIBED_KEY, true);
  } catch (error) {}
};
