import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { getPath } from '../../ritual/utils';

export class TopHeader extends Component {
  render(){
    return (
      <div className="top">
            <div id='header'>
                <div id='header_wrap'>
                    <div className="logo" id='logo'>
                      <Link to="/">
                        <img src="/static/img/logo.png" width="237" height="auto" alt="PowerOfTwo"/>
                      </Link>
                    </div>
                    <div id='profile_menu'>
                      <ul className='hnav'>
                          <li><Link to="/about/">About</Link></li>
                          <li><Link to="/faq/">FAQ</Link></li>
                          <li><a href="https://blog.heyritual.com/">Blog</a></li>
                          <li><a href="/login/">Login</a></li>
                          <li className="trial"><Link to={{pathname:"/signup/", from: getPath()}} className="button" onClick={this.handleClick}>Sign Up</Link></li>
                      </ul>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}

export default TopHeader
