import React from "react";
import LearnMoreButton from "../LearnMoreButton";
import Logo from "../Logo";
import zigzag from "../../assets/Zigzag.png";
import BannerWrapper from "./BannerWrapper";

const LandingBanner = () => {
  return (
    <BannerWrapper>
      <div className="landing-banner__container">
        <div className="landing-banner__left">
          <div className="landing-banner__text">
            <div className="text text-lg">
              Power of two is now{"\n"}
              <span>partnering with OurRitual</span>
            </div>
          </div>
          <LearnMoreButton className="left" />
        </div>
        <Logo className={"logo_main"} />
        <LearnMoreButton className="right" />
        <img src={zigzag} alt="zigzag" className="zigzag" />
      </div>
    </BannerWrapper>
  );
};

export default LandingBanner;
