import React from "react";
import LearnMoreButton from "../LearnMoreButton";
import Logo from "../Logo";
import BannerWrapper from "./BannerWrapper";

const StickyBanner = () => {
  return (
    <BannerWrapper>
      <div className="sticky-banner">
        <div className="text text-md">
          Invest in your relationship,{"\n"}
          <span>try OurRitual today</span>
        </div>
        <LearnMoreButton className="left" />
        <Logo className="sticky-banner__logo" />
        <LearnMoreButton className="right" />
      </div>
    </BannerWrapper>
  );
};

export default StickyBanner;
