import React, { Component } from 'react'
import { Link } from "react-router-dom";

import { login, statusLogin} from '../../Api/Client'
const queryString = require('query-string')



export class Content extends Component {
  constructor(props){
    super(props)
    const params = queryString.parse(props.location.search)

    this.state = {
      'username': null,
      'password': null,
      'params': params,
      'next': params['next'],
      'errors': {
        'username': null,
        'password': null,
        'all': null
      }
    }
  }

  validators = {
    username: (value) => {
      if(!value || value.length == 0){
        return 'Username is empty'
      }
      else if(value.length < 4){
        return "Username too short. Please at least 4 characters."
      }

      return null
    },
    password: (value) => {
      if(!value || value.length == 0){
        return 'Password is empty'
      }

      return null
    },
  }

  componentDidMount(){
    statusLogin().then(response => {
      if(response.data.is_authenticated){
        if(response.data.is_staff){
          this.redirectToDashboard()
        }
        else if(!response.data.plan){
          this.redirectToPricing()
        }
        else{
          this.redirectToDashboard()
        }
      }
    })
  }

  redirectToDashboard(){
    window.location.href = '/dashboard/'
  }

  redirectToPricing(){
    window.location.href = '/pricing/?signedup=login'
  }

  handleLogin = () => {
    if(this.validate('username', this.state.username)){
      return false
    }

    if(this.validate('password', this.state.password)){
      return false
    }

    login(
        this.state.username,
        this.state.password
    ).then(response => {
      if(this.state.next){
        window.location.href = this.state.next
      }
      else{
        this.redirectToDashboard()
      }
    }).catch(
      error => {
        this.setState({
          errors: {
            'all': error.response.data.message
          }
        })
      }
    )
  }

  validate(name, value){
    const error = this.validators[name](value)
    this.setState({
      errors: {
        [name]: error
      }
    })

    return error
  }

  onChange = (e) => {
    const self = this
    const name = e.target.name
    const value = e.target.value

    this.setState({
      [name]: value
    }, () => {
      self.validate(
          name,
          value
      )
    })
  }

  render(){
    return (
      <div className="login-form form">
         <h1>Log In</h1>
         <p>Do not have an account? <Link to={{pathname:"/signup/",from: "login"}}>Sign up!</Link></p>
         <p>Forgot your username or password? <a href="/account/password/reset/">Reset it.</a></p>

         <form className="login uniForm iecheck" method="POST" action="/login/">
           <div className="error">
            {this.state.errors.all}
           </div>
             <fieldset className="inlineLabels">
               <div id="div_id_username" className="ctrlHolder ">
                 <label for="id_username">
                     Username<span className="required-marker">*</span>
                 </label>
                 <div className="error">
                  {this.state.errors.username}
                 </div>
                 <input
                    autoFocus
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChange}
                    maxLength="30"
                    className="textinput textInput"
                    required=""
                    id="id_username"
                    />
               </div>
               <div id="div_id_password" className="ctrlHolder ">
                 <label for="id_password">
                     Password<span className="required-marker">*</span>
                 </label>
                 <div className="error">
                  {this.state.errors.password}
                 </div>
                 <input type="password" name="password" value={this.state.password} onChange={this.onChange} className="textinput textInput" required="" id="password" />
                 </div>

                 <div className="form_block">
                     {/* <div className='button login-button' onClick={this.handleLogin}>Log in »</div> */}
                     <div className='button login-button'><a href="/login/">Log in »</a></div>

                     <div className="login-remember">
                       <input type="checkbox" name="remember" className="checkboxinput" id="id_remember" />
                       <label for="id_remember">Remember Me</label>
                     </div>
                 </div>
             </fieldset>
         </form>

         <div className='login-bottom-text'>If you have any trouble logging in to your account, please contact us at <a href="mailto:info@poweroftwomarriage.com">info@poweroftwomarriage.com</a>.</div>
      </div>
    )
  }
}

export default Content
