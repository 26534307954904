import React, { Component } from 'react'
import TopHeader from '../../Header/component/TopHeader.js'

export class Main extends Component {
  render(){
    return (
      <>
        <TopHeader />
        <div>404 Not found</div>
      </>
    )
  }
}

export default Main
