import React, { Component } from 'react'

import { planLink, statusLogin } from '../../Api/Client'
import FullService from './FullService'
import Plans from './Plans'
const queryString = require('query-string')


export class Content extends Component {
  constructor(props){
    super(props)

    const params = queryString.parse(props.location.search)

    this.state = {
      isAuthenticated: false,
      fullServiceSelected: false,
      isLoading: false,
      'params': params,
      'planChosen': params['chosen'],
      'signedUp': params['signedup'],
    }
  }

  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(this.scrollToTop);
      window.scrollTo(0, c - c / 20);
    }
  }

  redirectToDashboard = () => {
    window.location.href = '/dashboard/'
  }

  helpingHand = () => {
    const {
        isLoading,
        isAuthenticated
    } = this.state

    const plan = 'helping-hand'

    if(!isAuthenticated){
      this.props.history.push({ pathname:`/signup/?plan=${plan}`, from: "pricing" })
      return
    }

    planLink(plan).then(response => {
      window.location.href = response.data.link
    })
  }

  marriageSaver = () => {
    const {
        isLoading,
        isAuthenticated
    } = this.state



    const plan = 'marriage-saver'

    if(!isAuthenticated){
      this.props.history.push({pathname: `/signup/?plan=${plan}`, from: "pricing"})
      return
    }

    planLink(plan).then(response => {
      window.location.href = response.data.link
    })
  }

  fullService = () => {
    this.scrollToTop()

    this.setState({
      fullServiceSelected: true
    })
  }

  updateLoginStatus = () => {
    statusLogin().then(response => {
      if(response.data.plan){
        this.redirectToDashboard()
      }

      this.setState({
        isLoading: false,
        isAuthenticated: response.data.is_authenticated,
      })      
    })
  }

  componentDidMount(){
    const { planChosen } = this.state

    if(planChosen){
      planLink(planChosen).then(response => {
        window.location.href = response.data.link
      })

      return
    }

    this.setState({
        isLoading: true
      },
      this.updateLoginStatus
    )
  }

  goBack = () => {
    this.scrollToTop()

    this.setState({
        'fullServiceSelected': !this.state.fullServiceSelected
    })
  }

  render(){
    const { fullServiceSelected } = this.state

    return (
      <>
      {
        fullServiceSelected ?
          <FullService
            goBack={this.goBack}
            scrollToTop={this.scrollToTop}
            startFreeTrial={this.marriageSaver}
          />
        : <Plans
            helpingHand={this.helpingHand}
            marriageSaver={this.marriageSaver}
            fullService={this.fullService}
            signedUp={this.state.signedUp}
          />
      }
      </>
    )
  }
}

export default Content
