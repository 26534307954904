import React, { Component } from 'react'

export class Content extends Component {
  render(){
    const {component: Component} = this.props

    return (
      <div id='content'>
        <Component {...this.props} />
      </div>
    )
  }
}

export default Content
