import React from "react";
import RedirectPage from "./RedirectPage";

const PRICING_PAGE = "https://pricing.ourritual.com/";

export const RedirectFromPricing = () => (
  <RedirectPage from="pricing" url={PRICING_PAGE} />
);

export const RedirectFromLanding = () => <RedirectPage from="landing_intro" />;

export const RedirectFromSignUp = () => <RedirectPage from="sign_up" />;

const PRIVACY_POLICY = "https://www.ourritual.com/privacy-policy";

export const RedirectFromPrivacyPolicy = () => (
  <RedirectPage from="privacy-policy" url={PRIVACY_POLICY} />
);

const TERMS_OF_USE = "https://www.ourritual.com/terms-and-conditions";

export const RedirectFromTermsOfUse = () => (
  <RedirectPage from="terms-of-use" url={TERMS_OF_USE} />
);

const Login = "https://app.ourritual.com/login";

export const RedirectFromLogin = () => (
  <RedirectPage from="Login" url={Login} />
);
