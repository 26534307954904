import React, { Component } from 'react'
import { Link } from "react-router-dom"

export class Content extends Component {
  render(){
    return (
      <>
        <div className="faq-banner-section section">
          <div className='section-wrapper'>
            <p className="text-center hero-title">How can we help?</p>
            <div className="about-head">
                <p className="text-center hero-subtext">
                  Get started talking with a coach today.
                </p>
            </div>
            <div className="text-center seam-button">
              <Link to={{pathname:"/signup/",from: "faq"}}>
                <img src='/static/img/arrow_button.png' className='about-signup' alt='Signup'/>
              </Link>
              <br/>
              <div className='about-join'>
                <strong>Join now!</strong>
              </div>
            </div>
          </div>
        </div>


        <div className="faq-question-section section">
          <div className='section-wrapper'>
            <div className="faq-question-container">
                <p className="faq-question">How much does it cost?</p>

                <p className="faq-answer">
                    It's less than a dollar a day with an annual plan! Or you can do our monthly plan, with no contract and cancellation anytime with one simple click from your account settings page.
                </p>
            </div>

            <div className="faq-question-container">
                <p className="faq-question">Is it effective?</p>

                <p className="faq-answer">In a rigorous randomized study, participants using the online program for just a
                    few minutes each week saw <strong>significantly more improvement</strong> (15% in just two months!) in how satisfied
                    they were with their marriages than people who did not use the program.</p>
            </div>

            <div className="faq-question-container">
                <p className="faq-question">How does it work?</p>

                <p className="faq-answer">When you become a member, your coach will prepare a <strong>personalized list of short
                    activities</strong> that will help you to learn, practice and implement new skills. At any time you can also
                    always <strong>browse our extensive library</strong> of interactive multi-media activities, podcasts, worksheets,
                    videos, email series and more.
                </p>
            </div>

            <div className="faq-question-container">
                <p className="faq-question">Does my spouse need to join?</p>

                <p className="faq-answer">No, <strong>your spouse does not need to join</strong>. The program is designed so you can do it
                    alone or as a couple. Your coach can help you determine the best way to make the program work for
                    you.
                  </p>
            </div>

            <div className="faq-question-container">
                <p className="faq-question">We aren’t married yet, can we still benefit from the program?</p>

                <p className="faq-answer">Absolutely! The skills we teach are valuable at any stage of a relationship. We
                    think the earlier you learn these skills the better!
                </p>
            </div>

            <div className="faq-question-container">
                <p className="faq-question">We are a same sex couple. Can we benefit from the Power of Two?</p>

                <p className="faq-answer">Absolutely! We welcome all couples (and even single folks) looking to learn the
                    skills necessary for a thriving partnership.
                </p>
            </div>
          </div>
        </div>



        <div className="faq-end-section section">
          <div className='section-wrapper'>
            <div className="special-container">
                <hr className='divider'/>

                <p className="text-center big-blue">
                  Contact us if you still have questions
                  <br/>
                  <a href="mailto:info@poweroftwomarriage.com">info@poweroftwomarriage.com</a>
                </p>

                <hr className='divider'/>
            </div>

            <div className="text-center">
                <Link to={{pathname:"/signup/",from: "faq"}}>
                  <img className='about-signup' src="/static/img/arrow_button.png"/>
                </Link>

                <p className="text-center big-bold">Sign Up right now</p>
            </div>

          </div>

        </div>

      </>
    )
  }
}

export default Content
